export enum StaffCategories {
  Board = "board",
  Administration = "administration",
  Staff = "staff",
  MiddleSchool = "middle school",
  HighSchool = "high school",
}

export enum FactLabels {
  FunFact = "Fun Fact",
  MeaningfulQuote = "A Quote I Find Meaningful",
  FavoriteWeekendActivity = "Favorite Weekend Activity",
  FavoriteBook = "Favorite Book",
}
