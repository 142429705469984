import classNames from "classnames";
import { Button } from "components/button/Button.component";
import { BlueGrouping } from "components/color-groupings/blue-grouping/BlueGrouping.component";
import { WhiteGrouping } from "components/color-groupings/white-grouping/WhiteGrouping.component";
import { GraduationCard } from "components/graduation-card/GraduationCard.component";
import { Link } from "components/link/Link.component";
import { StaffCards } from "components/page-specific-components/schools/components/staff-cards/StaffCards.component";
import { staffCards as employeeCards } from "components/page-specific-components/schools/components/staff-cards/StaffCards.config";
import { sortStaffCards } from "components/page-specific-components/schools/components/staff-cards/StaffCards.helpers";
import { SEO } from "components/seo/SEO.component";
import { motion } from "framer-motion";
import { StaticImage } from "gatsby-plugin-image";
import { useRelatedPages } from "hooks/relatedPages.hook";
import React, { useRef, useState } from "react";
import linksService from "services/links.service";
import { convertRemToPixels } from "services/remToPixels";
import { RELATED_PAGES_LABELS } from "components/constants";

const OurStory = () => {
  const ourStoryRef = useRef(null);
  const [isShowingFullStory, toggleIsShowingFullStory] = useState(false);
  const { boardCards } = sortStaffCards(employeeCards);

  const bubbleColors = ["var(--blue)", "var(--tertiary)", "var(--secondary)"];

  const handleToggle = () => {
    if (isShowingFullStory) {
      window.scrollTo({
        left: 0,
        top:
          ourStoryRef.current.offsetParent.offsetTop -
          convertRemToPixels("4rem"),
        behavior: "smooth",
      });
      setTimeout(
        () => toggleIsShowingFullStory((prevIsShowing) => !prevIsShowing),
        500
      );
    } else {
      toggleIsShowingFullStory((prevIsShowing) => !prevIsShowing);
    }
  };

  const bubbleWrapperVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.05,
        type: "tween",
      },
    },
  };

  const bubbleVariants = {
    hidden: {
      opacity: 0,
      transform: "translateX(-5rem)",
    },
    visible: {
      opacity: 1,
      transform: "translateX(0rem)",
    },
  };

  const bubbles = [
    {
      label:
        "Ambitious pursuit of truth, justice & beauty in a diverse community",
      link: linksService.pacbayDemographics(),
    },
    {
      label: "Joyful learning in an ecumenical community",
      link: linksService.statementOnEcumenicalism(),
    },
    {
      label: "Worldly engagement; connection for life",
      link: linksService.connectionsForLife(),
    },
  ];

  const shortenedTextLength = "20rem";

  const graduationCardProps = {
    style: { borderRadius: "0.83rem" },
    height: "20rem",
    alt: "",
    placeholder: "blurred",
    layout: "constrained",
  };

  useRelatedPages([
    {
      label: RELATED_PAGES_LABELS.MIDDLE_SCHOOL,
      link: linksService.middleSchool(),
    },
    {
      label: RELATED_PAGES_LABELS.HIGH_SCHOOL,
      link: linksService.highSchool(),
    },
  ]);

  return (
    <>
      <SEO title="Our Story" />
      <main>
        <WhiteGrouping>
          <section
            className="layout fixed-layout fix-header-overlap"
            style={{ paddingBottom: "4rem" }}
          >
            <h2>Our Values</h2>
            <motion.div
              className="our-story-bubble-wrapper"
              variants={bubbleWrapperVariants}
              initial="hidden"
              animate="visible"
            >
              {bubbles.map((bubble, bubbleIndex) => {
                const chosenColorVariantIndex =
                  bubbleIndex % bubbleColors.length;
                return (
                  <>
                    {bubble.link ? (
                      <Link
                        to={bubble.link ?? ""}
                        newTab
                        className="show-link-icon inherit-parent-color"
                      >
                        <motion.div
                          key={bubble.label}
                          className="bubble"
                          style={{
                            border: `solid 0.5rem ${bubbleColors[chosenColorVariantIndex]}`,
                          }}
                          variants={bubbleVariants}
                        >
                          <p>{bubble.label}</p>
                        </motion.div>
                      </Link>
                    ) : (
                      <motion.div
                        key={bubble.label}
                        className="bubble"
                        variants={bubbleVariants}
                        style={{
                          border: `solid 0.5rem ${bubbleColors[chosenColorVariantIndex]}`,
                        }}
                      >
                        <p>{bubble.label}</p>
                      </motion.div>
                    )}
                  </>
                );
              })}
            </motion.div>
          </section>
        </WhiteGrouping>
        <BlueGrouping>
          <section
            ref={ourStoryRef}
            className="layout"
            style={{ paddingTop: "4rem", paddingBottom: "4rem" }}
          >
            <h2 className="no-margin">Our Story</h2>
            <motion.div
              initial={{ height: shortenedTextLength }}
              animate={{
                height: isShowingFullStory ? "100%" : shortenedTextLength,
              }}
              transition={{ duration: 0.2, type: "tween" }}
              className={classNames("our-story-wrapper", {
                "our-story-mask": !isShowingFullStory,
              })}
            >
              <h4>
                Our Identity: A Story of Courage, Redemption, and Transformation
              </h4>
              <p>
                At its heart, PacBay community dedicates itself to academic
                excellence, joyful learning, and the staunch pursuit of truth,
                justice, and beauty. And, our journey is one of redemptive
                transformation, courageous renewal, and intentional growth, and
                we are unafraid to tell our story to all who will listen.
              </p>
              <p>
                Our story, recounted below, was also told in&nbsp;
                <Link to={linksService.theUndefeatedArticle()} newTab>
                  ESPN's Undefeated publication
                </Link>
                . As our nation continues to grapple with its history and
                continued insidious and overt presence of racism, our story
                offers a vision of what is possible - humbly calling out the
                wrong, standing up for what is right, and charting out a common
                path to uplift our shared humanity on this side of heaven.
              </p>
              <h4>Our Complicated History</h4>
              <p>
                From 1955 to 2018, the two campuses surrounded by eucalyptus
                groves just off Highway 1 in Pacifica had a different name: Alma
                Heights Christian School. Alma Heights operated as a private
                school and ministry under the missionary organization, Pillar of
                Fire. The school itself was named after Pillar of Fire's
                founder, Alma White - who was a known white supremacist from the
                early to mid 20th century in New Jersey with associations to the
                Ku Klux Klan. While Alma Heights Christian School was a somewhat
                autonomous entity in California and did not act upon or
                consciously support her troubling ideologies, the school's
                continued operation under the name of an intensely disturbing
                individual was problematic.&nbsp;
                <b>
                  There is power in a name, even if the name is kept only out of
                  ignorant complicity or complacency.
                </b>
              </p>
              <p>
                Alma Heights' Advisory Board (mostly school parents and alumni
                parents) realized a change was necessary. In 2017, they reached
                out to Dr. Michael Chen and asked him to come alongside them to
                revitalize the school and its vision. Dr. Chen had a long
                history in&nbsp;
                <Link to={linksService.caceArticle()} newTab>
                  Christ-centered education and leadership
                </Link>
                ,&nbsp;
                <Link to={linksService.cedaPanel()} newTab>
                  advancing equity and social justice in schools
                </Link>
                &nbsp;through curriculum innovation, experiential learning, and
                community and global service and engagement. Dr. Chen agreed, on
                one condition: the school's name be changed, all association
                with Alma White and Pillar of Fire be severed and repudiated,
                and a new school be launched.
              </p>
              <p>
                In January of 2018, that board unanimously voted not only to
                change the school's name but to enact a complete organizational
                change.&nbsp;
                <b>
                  Alma Heights Christian School was officially terminated as an
                  entity
                </b>
                , and through the next several months, a completely new school
                was formed to take its place. A new community with fresh vision
                and vigor came into being through the tireless efforts of
                several essential people: Alan Aimi, the advisory board's
                president, led the change alongside Dr. David Gross, the former
                head of school. Susan Yem, the new school's Director of
                Advancement, brought together stakeholders - students, families,
                school staff, and alumni - to help the existing community
                embrace the process of change. Jim Sweeney was the legal mind
                that helped navigate the complexities of the new incorporation
                and organizational change. And overall, Dr. Michael Chen
                championed this change. In June of 2018, their collective vision
                was fully realized.{" "}
                <b>
                  Alma Heights Christian School no longer existed; in its place
                  stood a brand new school, fully incorporated with an
                  independent school board, and no affiliations or ties to the
                  previous ministries. This school was Pacific Bay Christian
                  School.
                </b>
              </p>
              <h4>Our Redemptive Name</h4>
              <p>
                There is power in a name. The naming process for this new school
                was intentional and deliberate, covered with prayer. Our new
                school's name needed to lay the foundation for a community of
                strength, character, justice, and faithfulness. And so this name
                was chosen:
              </p>
              <ul>
                <li>
                  <strong>Pacific</strong>: The campus overlooks the majestic
                  Pacific Ocean, and that western horizon draws our eyes beyond
                  ourselves to embrace a global perspective. PacBay would not
                  embrace insularity, exclusivity, or eliteness. Rather, it
                  strives to nurture in each student a perspective of
                  inclusivity and global citizenship, championing a unified
                  vision of humanity.
                </li>
                <li>
                  <strong>Bay</strong>: While keeping an understanding of the
                  importance of a global perspective, we are also rooted in our
                  local community. PacBay opens its doors to the entire Bay
                  Area, welcoming students from a diversity of backgrounds,
                  beliefs, perspectives, and lived experiences.
                </li>
                <li>
                  <strong>Christian</strong>: From its very founding, PacBay
                  aspires to be a part of God's redemptive story. Christ is at
                  its center from an ecumenical standpoint, bringing together
                  all Christ-centered viewpoints and denominations in its
                  community. PacBay aspires to live out “mere Christianity” by
                  welcoming all as Christ and emphasizing love and unity as
                  integral to the Christian faith. It aims to be a sanctuary
                  where all students can flourish and come together to learn,
                  pushing themselves towards excellence and towards becoming the
                  whole persons God wants them to be.
                </li>
              </ul>
              <h4>Our Transformative Vision</h4>
              <p>
                Pacific Bay Christian School is committed to continued and
                courageous growth. At the heart of its academic mindset lies the
                desire to nurture all students in virtue, leading them towards a
                love of truth, justice, and beauty. PacBay is dedicated to
                educational innovation and looking at conventional systems from
                fresh and unique perspectives. We see this throughout the
                curriculum and the structure of the school day, from their Flex
                Days to their Outdoor Education program. And, PacBay animates
                its commitment to equity through its culminating senior seminar,
                where every senior explores issues at the intersection of their
                life's pursuits with faith and justice.
              </p>
              <p>
                PacBay takes its identity, its history, its name, and its vision
                seriously: it continues to further the narrative of joyful
                renewal by nurturing virtue in its students and pursuing justice
                and equity in all it does. Above all, its vision is one of
                unity: where students of all backgrounds work alongside the
                staff and faculty to cultivate their academic environment
                together. As students graduate from PacBay and go out into the
                world, may they be empowered with boldness and virtue to change
                it for the better, and fight for truth, justice, and beauty.
              </p>
            </motion.div>
            <Button
              onClick={handleToggle}
              className="no-shadow-or-bg-color minimal-padding"
              style={{ textDecoration: "underline" }}
            >
              {!isShowingFullStory
                ? "Read more to hear our story"
                : "Show Less"}
            </Button>
          </section>
        </BlueGrouping>
        <WhiteGrouping>
          <section
            className="layout"
            style={{ paddingTop: "4rem", paddingBottom: "2rem" }}
          >
            <h2 className="no-margin" style={{ marginBottom: "2rem" }}>
              Our Graduates
            </h2>
            <div className="graduation-images-grid-wrapper">
              <GraduationCard caption="Class of 2023">
                <StaticImage
                  src="../images/jpg/class_of_2023.jpg"
                  {...graduationCardProps}
                />
              </GraduationCard>
              <GraduationCard
                caption="Class of 2022"
                videoLink="https://www.youtube.com/watch?v=qP0JpJ2bZ68"
              >
                <StaticImage
                  src="../images/jpg/class_of_2022.jpg"
                  {...graduationCardProps}
                />
              </GraduationCard>
              <GraduationCard
                caption="Class of 2021"
                videoLink="https://www.youtube.com/watch?v=vMuSzNFIJQ4"
              >
                <StaticImage
                  src="../images/jpg/class_of_2021(v2).jpg"
                  {...graduationCardProps}
                />
              </GraduationCard>
              <GraduationCard caption="Class of 2020">
                <StaticImage
                  src="../images/jpg/class_of_2020.jpg"
                  {...graduationCardProps}
                />
              </GraduationCard>
              <GraduationCard caption="Class of 2019">
                <StaticImage
                  src="../images/jpg/class_of_2019.jpg"
                  {...graduationCardProps}
                />
              </GraduationCard>
            </div>
            <h2>Our Leaders</h2>
            <StaffCards
              cards={boardCards}
              className="board-grid-container"
              style={{ height: "17rem" }}
            />
          </section>
        </WhiteGrouping>
      </main>
    </>
  );
};

export default OurStory;
