import { Button } from "components/button/Button.component";
import { Link } from "components/link/Link.component";
import { Cancel } from "components/svg-wrapper/components/shapes/Cancel.svg";
import { Info } from "components/svg-wrapper/components/shapes/Info.svg";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { Avatar } from "./components/avatar/Avatar.component";
import "./StaffCard.styles.scss";

export const StaffCard = ({ card, style }) => {
  const [isCardFlipped, toggleIsCardFlipped] = useState(false);
  const { name, title, image, education, fact, jobTitle } = card;
  const hasBackCardContent = education || fact;

  const handleCardFlip = () => {
    if (hasBackCardContent) {
      toggleIsCardFlipped(!isCardFlipped);
    }
  };

  const renderMultipleTitles = (information) => {
    const renderTitle = (title) => <p className="card-title">{title}</p>;

    if (Array.isArray(information)) {
      return information.map(({ title, link }) => {
        return (
          <>
            {link ? (
              <Link to={link} onClick={(e) => e.stopPropagation()}>
                {renderTitle(title)}
              </Link>
            ) : (
              renderTitle(title)
            )}
          </>
        );
      });
    } else {
      return renderTitle(information);
    }
  };

  return (
    <motion.article
      whileHover={{ scale: hasBackCardContent ? 1.02 : 1 }}
      className="flip-card"
      onClick={handleCardFlip}
      style={{
        cursor: hasBackCardContent ? "pointer" : "default",
      }}
    >
      <div
        className="flip-card-inner"
        style={{
          transform: isCardFlipped ? "rotateY(180deg)" : "rotateY(0deg)",
        }}
      >
        <motion.div
          whileHover={{
            boxShadow: hasBackCardContent ? "var(--level-2)" : "var(--level-1)",
          }}
          className="flip-card-front"
          style={style}
        >
          {hasBackCardContent && (
            <Button
              className="info-button no-shadow-or-bg-color"
              labelStyle={{ margin: 0 }}
              onClick={handleCardFlip}
            >
              <Info width="1rem" color="var(--black)" />
            </Button>
          )}
          <Avatar dimension={7.5} image={image} />
          <p className="card-name">{name}</p>
          {renderMultipleTitles(title)}
        </motion.div>
        {hasBackCardContent && (
          <motion.div
            whileHover={{ boxShadow: "var(--level-2)" }}
            className="flip-card-back"
            style={{ ...style, padding: "1.5rem" }}
          >
            <Button
              className="info-button no-shadow-or-bg-color"
              labelStyle={{ margin: 0 }}
              onClick={handleCardFlip}
            >
              <Cancel width="1rem" color="var(--black)" />
            </Button>
            <p
              className="card-name"
              style={{ textDecoration: "underline", margin: "0.11rem 0" }}
            >
              {name}
            </p>
            {education && (
              <div>
                <p className="category-title">Education:</p>
                <p className="category-text">{education}</p>
              </div>
            )}
            {fact && (
              <div>
                <p className="category-title">{fact.label}:</p>
                <p className="category-text">{fact.text}</p>
              </div>
            )}
            {jobTitle && (
              <div>
                <p className="category-title">Job Title:</p>
                <p className="category-text">{jobTitle}</p>
              </div>
            )}
          </motion.div>
        )}
      </div>
    </motion.article>
  );
};
