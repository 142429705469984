import { StaffCard } from "components/staff-card/StaffCard.component";
import React from "react";
import "./StaffCards.styles.scss";

export const StaffCards = ({ heading, cards, className, style }) => {
  return (
    <section style={{ marginBottom: "5.55rem" }}>
      {heading && <h3>{heading}</h3>}
      <div className={`${className} grid-container`}>
        {cards.map((card) => {
          return <StaffCard key={card.name} card={card} style={style} />;
        })}
      </div>
    </section>
  );
};
