import AvieLouieSmith from "images/jpg/staff/Avie_Louie-Smith.jpg";
import BeckyGross from "images/jpg/staff/Becky_Gross.jpg";
import ChrisSmithson from "images/jpg/staff/Chris_Smithson.jpg";
import CristinaMedino from "images/jpg/staff/Cristina_Medino.jpg";
import DavidGross from "images/jpg/staff/David_Gross.jpg";
import DawnMulliken from "images/jpg/staff/Dawn_Mulliken.jpg";
import DawnPucci from "images/jpg/staff/Dawn_Pucci.jpg";
import DeannaHaro from "images/jpg/staff/Deanna_Haro.jpg";
import DenetteMoore from "images/jpg/staff/Denette_Moore.jpg";
import EliaRudoni from "images/jpg/staff/Elia_Rudoni.jpg";
import ErikEllefsen from "images/jpg/staff/Erik_Ellefsen.jpg";
import FranciscoBonilla from "images/jpg/staff/Francisco_Bonilla.jpg";
import HannahKim from "images/jpg/staff/Hannah_Kim.jpg";
import JeremyChen from "images/jpg/staff/Jeremy_Chen.jpg";
import JonathanBonilla from "images/jpg/staff/Jonathan_Bonilla.jpg";
import LizelleKeane from "images/jpg/staff/Lizelle_Keane.jpg";
import MariaPanameño from "images/jpg/staff/Maria_Panameño2.jpg";
import MelanieSpehr from "images/jpg/staff/Melanie_Spehr2.jpg";
import MichaelChen from "images/jpg/staff/Michael_Chen.jpg";
import MitchSalerno from "images/jpg/staff/Mitch_Salerno.jpg";
import NatalieTalbotThorne from "images/jpg/staff/Natalie_Talbot-Thorne.jpg";
// import NoPhoto from "images/jpg/staff/No_Photo.jpg";
import PaulTang from "images/jpg/staff/Paul_Tang.jpg";
import SamuelYeeles from "images/jpg/staff/Samuel_Yeeles.jpg";
import StaceyHo from "images/jpg/staff/Stacey_Ho.jpg";
import SteveShargel from "images/jpg/staff/Steve_Shargel.jpg";
import SusanBerrend from "images/jpg/staff/Susan_Berrend.jpg";
import TanyaHernandez from "images/jpg/staff/Tanya_Hernandez.jpg";
import VincentChan from "images/jpg/staff/Vincent_Chan.jpg";
import linksService from "services/links.service";
import { FactLabels, StaffCategories } from "./StaffCards.enum";
import { createFact } from "./StaffCards.helpers";

export const staffCards = [
  //* Middle School
  {
    name: "Natalie Talbot-Thorne",
    title: "Dean of Middle School",
    image: NatalieTalbotThorne,
    category: StaffCategories.MiddleSchool,
    education:
      "M.A. in Education, Certified Masters in Athletics Administration",
    fact: createFact(
      FactLabels.FavoriteWeekendActivity,
      "49ers games and tailgating, time with family"
    ),
  },
  {
    name: "Cristina Medino",
    title: "Science / Math",
    image: CristinaMedino,
    category: StaffCategories.MiddleSchool,
    education: "B.S. in Chemical Engineering",
    fact: createFact(
      FactLabels.MeaningfulQuote,
      '"Anyone who has never made a mistake has never tried anything new." - Albert Einstein'
    ),
  },
  {
    name: "Dawn Pucci",
    title: "Christian Studies",
    image: DawnPucci,
    category: StaffCategories.MiddleSchool,
  },
  {
    name: "Dr. David Gross",
    title: "Outdoor Science",
    image: DavidGross,
    category: StaffCategories.MiddleSchool,
  },
  {
    name: "Avie Louie-Smith",
    title: "STEM",
    image: AvieLouieSmith,
    category: StaffCategories.MiddleSchool,
  },
  {
    name: "Denette Moore",
    title: "Humanities",
    image: DenetteMoore,
    category: StaffCategories.MiddleSchool,
  },
  {
    name: "Lizelle Keane",
    title: "STEM",
    image: LizelleKeane,
    category: StaffCategories.MiddleSchool,
  },
  //* High School
  {
    name: "Jeremy Chen",
    title: "Associate Dean of Students",
    image: JeremyChen,
    category: StaffCategories.HighSchool,
  },
  {
    name: "Chris Smithson",
    title: "Christian Studies / Humanities",
    image: ChrisSmithson,
    category: StaffCategories.HighSchool,
    education: "M.A. in Religion",
    fact: createFact(
      FactLabels.FavoriteBook,
      "\"Adam Raccoon at Forever Falls.\" My father often read me this children's story. It's a simple analogy of the gospel that's never left me."
    ),
  },
  {
    name: "Dawn Mulliken",
    title: "French / Humanities",
    image: DawnMulliken,
    category: StaffCategories.HighSchool,
    education: "B.A. in History and French",
    fact: createFact(
      FactLabels.FunFact,
      "I have a huge collection of pencils from all over the world."
    ),
  },
  {
    name: "Dr. Elia Rudoni",
    title: "Latin / Humanities",
    image: EliaRudoni,
    category: StaffCategories.HighSchool,
  },
  {
    name: "Maria Panameño",
    title: "Spanish",
    image: MariaPanameño,
    category: StaffCategories.HighSchool,
    education: "B.A. in Sociology",
    fact: createFact(
      FactLabels.MeaningfulQuote,
      "Reach for the moon, even if you miss you will land among the stars."
    ),
  },
  {
    name: "Dr. Paul Tang",
    title: "STEM",
    image: PaulTang,
    category: StaffCategories.HighSchool,
    education: "Ph.D. in Materials Science and Engineering",
    fact: createFact(
      FactLabels.MeaningfulQuote,
      "If all you got is a hammer, everything begins to look like nails."
    ),
  },
  {
    name: "Susan Berrend",
    title: "STEM",
    image: SusanBerrend,
    category: StaffCategories.HighSchool,
    education: "B.A. in Ecology and Systematics; M.S. in Science Education",
    fact: createFact(
      FactLabels.FunFact,
      "I did graduate work in Sweden, and had to learn Swedish quickly to do so."
    ),
  },
  {
    name: "Dr. Steve Shargel",
    title: "Christian Studies",
    image: SteveShargel,
    category: StaffCategories.HighSchool,
  },
  {
    name: "Melanie Spehr",
    title: "Design / Art",
    image: MelanieSpehr,
    category: StaffCategories.HighSchool,
  },
  {
    name: "Becky Gross",
    title: "Theater",
    image: BeckyGross,
    category: StaffCategories.HighSchool,
  },
  //* Administration
  {
    name: "Dr. Michael Chen",
    title: "Head of School",
    image: MichaelChen,
    category: StaffCategories.Administration,
    education:
      "B.A. in Physics; M.A. in International Educational Development; Ed.D. in Educational Leadership and Development",
    fact: createFact(
      FactLabels.FavoriteBook,
      '"The Education of an Idealist" by Samatha Power'
    ),
  },
  {
    name: "Vincent Chan",
    title: "Chief Operating Officer",
    image: VincentChan,
    category: StaffCategories.Administration,
    education: "B.A. in Computer Science",
    fact: createFact(
      FactLabels.FavoriteWeekendActivity,
      "Playing Legos with my kids"
    ),
  },
  {
    name: "Stacey Ho",
    title: [
      { title: "College Counseling", link: null },
      { title: "Website", link: linksService.collegeCounseling() },
    ],
    image: StaceyHo,
    category: StaffCategories.Administration,
    education: "B.A. in Communication Studies, M.Ed. in School Counseling",
    fact: createFact(
      FactLabels.MeaningfulQuote,
      '"Darkness cannot drive out darkness; only light can do that. Hate cannot drive out hate; only love can do that." - Martin Luther King, Jr.'
    ),
  },
  {
    name: "Tanya Hernandez",
    title: "Director of Admissions",
    image: TanyaHernandez,
    category: StaffCategories.Administration,
  },
  //* Staff
  {
    name: "Samuel Yeeles",
    title: "Director of Facilities",
    image: SamuelYeeles,
    category: StaffCategories.Staff,
    education: "B.A. in Industrial Design",
    fact: createFact(
      FactLabels.FunFact,
      "I am the Youth Leader at Sea View Baptist Church."
    ),
  },
  {
    name: "Jonathan Bonilla",
    title: "PacBay Chef",
    image: JonathanBonilla,
    category: StaffCategories.Staff,
  },
  {
    name: "Francisco Bonilla",
    title: "PacBay Chef",
    image: FranciscoBonilla,
    category: StaffCategories.Staff,
  },
  {
    name: "Deanna Haro",
    title: "Receptionist / Accounting",
    image: DeannaHaro,
    category: StaffCategories.Staff,
  },
  //* Board
  {
    name: "Erik Ellefsen, M.A.",
    title:
      "Board President / Director of Networks and Improvement at Baylor University",
    image: ErikEllefsen,
    category: StaffCategories.Board,
  },
  {
    name: "Michael Chen, Ed.D",
    title: "Secretary / Head of School at Pacific Bay Christian School",
    image: MichaelChen,
    category: StaffCategories.Board,
  },
  {
    name: "Hannah Kim, M.A.",
    title:
      "VP / Director, Business & Entrepreneurship at Valley Christian Schools",
    image: HannahKim,
    category: StaffCategories.Board,
  },
  {
    name: "Mitch Salerno, Ed.D",
    title: "Treasurer / Head of School at Windermere Preparatory Academy",
    image: MitchSalerno,
    category: StaffCategories.Board,
  },
  {
    name: "David Gross, Ed.D",
    title: "Outdoor Science at Pacific Bay Christian School",
    image: DavidGross,
    category: StaffCategories.Board,
  },
];
