import { Link } from "components/link/Link.component";
import { SvgWrapper } from "components/svg-wrapper/SvgWrapper.component";
import { SVGIcons } from "components/svg-wrapper/SvgWrapper.enum";
import { motion } from "framer-motion";
import React from "react";
import "./GraduationCard.styles.scss";

export const GraduationCard = ({ caption, children, videoLink }) => {
  const hasVideoLink = Boolean(videoLink);

  const imageAndCaptionRender = () => {
    const positionAbsoluteOffset = "0.75rem";
    return (
      <div className="graduation-image">
        <div style={{ position: "relative" }}>
          {hasVideoLink && (
            <div
              style={{
                position: "absolute",
                top: positionAbsoluteOffset,
                left: positionAbsoluteOffset,
                zIndex: 1,
              }}
            >
              <SvgWrapper
                iconType={SVGIcons.Movie}
                width="1.75rem"
                color="var(--black)"
              />
            </div>
          )}
          {children}
        </div>
        <caption>{caption}</caption>
      </div>
    );
  };

  let component;
  if (hasVideoLink) {
    component = (
      <motion.div
        whileHover={{
          scale: 1.01,
        }}
      >
        <Link
          to={videoLink}
          newTab
          className="no-underline inherit-parent-color no-hover"
        >
          {imageAndCaptionRender()}
        </Link>
      </motion.div>
    );
  } else {
    component = imageAndCaptionRender();
  }
  return component;
};
