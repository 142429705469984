import React from "react";
import "./Avatar.styles.scss";

export const Avatar = ({ dimension, image }) => {
  return (
    <div
      className="avatar-wrapper"
      style={{
        width: `${dimension}rem`,
      }}
    >
      <div>
        <svg width={`${dimension}rem`} height={`${dimension}rem`}>
          <defs>
            <clipPath id="circleView">
              <circle
                cx={`${dimension / 2}rem`}
                cy={`${dimension / 2 - 0.28}rem`}
                r={`${(dimension / 2) * 0.8}rem`}
              />
            </clipPath>
          </defs>
          <image
            width={`${dimension}rem`}
            height={`${dimension}rem`}
            xlinkHref={image}
            clipPath="url(#circleView)"
          />
        </svg>
      </div>
    </div>
  );
};
