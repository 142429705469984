import { StaffCategories } from "./StaffCards.enum";

export const sortStaffCards = (cards) => {
  let middleSchoolCards = [];
  let highSchoolCards = [];
  let administrationCards = [];
  let staffCards = [];
  let boardCards = [];
  cards.forEach((card) => {
    switch (card.category) {
      case StaffCategories.MiddleSchool:
        middleSchoolCards.push(card);
        break;
      case StaffCategories.HighSchool:
        highSchoolCards.push(card);
        break;
      case StaffCategories.Administration:
        administrationCards.push(card);
        break;
      case StaffCategories.Staff:
        staffCards.push(card);
        break;
      case StaffCategories.Board:
        boardCards.push(card);
        break;
    }
  });
  return {
    middleSchoolCards,
    highSchoolCards,
    administrationCards,
    staffCards,
    boardCards,
  };
};

export const createFact = (label, text) => ({
  label,
  text,
});
