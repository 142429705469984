import React from "react";

export const Cancel = ({
  width,
  height,
  color,
  opacity = "1",
  maxWidth,
  maxHeight,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <svg
      width={width}
      height={height}
      style={{ maxWidth, maxHeight }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      viewBox="0 0 33 31"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <g
          id="Icons/Closed-Menu-Button"
          transform="translate(0.000000, 4.000000)"
          stroke={color}
          strokeOpacity={opacity}
          strokeWidth="3"
        >
          <g id="SVG-Layer" transform="translate(3.473909, -0.972755)">
            <g
              id="Group"
              transform="translate(13.000000, 12.000000) scale(-1, 1) translate(-13.000000, -12.000000) "
            >
              <line x1="0" y1="2" x2="24" y2="24" id="Path"></line>
              <line x1="0" y1="24" x2="24" y2="2" id="Path"></line>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
