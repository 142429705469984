import React from "react";

export const Info = ({
  width,
  height,
  color,
  opacity = "1",
  maxWidth,
  maxHeight,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <svg
      width={width}
      height={height}
      style={{ maxWidth, maxHeight }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      viewBox="0 0 31 31"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Icons/Closed-Menu-Button-Copy"
          transform="translate(-1.000000, 4.000000)"
          stroke={color}
          strokeOpacity={opacity}
        >
          <g id="Group" transform="translate(1.000000, -4.000000)">
            <circle
              id="Oval"
              strokeWidth="2"
              cx="15.5"
              cy="15.5"
              r="14"
            ></circle>
            <rect
              id="Rectangle"
              fill={color}
              fillOpacity={opacity}
              x="14.5"
              y="12.5"
              width="2"
              height="12"
            ></rect>
            <circle
              id="Oval"
              fill={color}
              fillOpacity={opacity}
              cx="15.5"
              cy="8"
              r="1.5"
            ></circle>
          </g>
        </g>
      </g>
    </svg>
  );
};
